import styled from "styled-components";
import { motion as m } from "framer-motion";
import { StyledButton,Root } from '../../utils/global_styles'

export const EnviromentHolder = styled(m.div)`
margin-top:20px;
display:flex;
margin: 0px -10px;
flex-wrap: wrap;

`
export const SRoot = styled(Root)`
padding-top:0px;
min-height:unset

`



